#skill_sec {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 1vmax;
    align-items: center;
    gap: 3vmax;
    margin-bottom: 8vmax;
    padding-top: 5vmax;
}

#sk_divs {
    width: 98%;
    height: fit-content;
    padding-bottom: 2vmax;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    align-items: baseline;
    column-gap: 3vmax;
    row-gap: 3vmax;
}

.skills_div {
    background-color: var(--color-white-faint-soft);
    width: 30vmax;
    height: fit-content;
    border-radius: 1vmax;
    box-shadow: 0 0 20px -3px black;

}

.skill_title {
    width: 100%;
    height: 3.5vmax;
    background-color: var(--color-secondary);
    border-radius: 1vmax 1vmax 0 0;
    border: 3px solid var(--color-secondary);
    border-bottom: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 1.3vmax;
}

.skill_div {
    width: 100%;
    height: fit-content;
    border: 3px solid var(--color-secondary);
    border-top: none;
    border-radius: 0 0 1vmax 1vmax;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    align-items: center;
    row-gap: 1vmax;
    padding: 1vmax 0;
    column-gap: 1.5vmax;
}

.skill {
    /* width: fit-content; */
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.skill_back_circ {
    width: 5.5vmax;
    height: 6.5vmax;
    background-color: #BD6B6B;
    border-radius: 50vmax;
    display: flex;
    justify-content: center;
    align-items: center;

}

.skill_white_back {
    width: 94%;
    height: 94%;
    background-color: var(--color-white);
    border-radius: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: none; */
}


.skill_logo {
    width: 3vmax;
}

.skill_logo:hover {
    filter: drop-shadow(0px 0px 2px #000000);
}

.skill_detail {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    font-weight: 400;
}

.skill_name {
    width: 100%;
    padding-top: .5vmax;
    text-align: center;
    font-size: 1vmax;
    line-height: 1vmax;
    height: fit-content;
}

.skill_level {
    width: 100%;
    padding-top: .5vmax;
    text-align: center;
    font-size: .65vmax;
    line-height: .65vmax;
    font-weight: 400;
    color: var(--color-white-faint);
}


@media screen and (max-width:600px) {
    #skill_sec {
        padding: 0 5vmin;
        gap: 10vmin;
        padding-top: 6vmin;
    }

    #sk_divs {
        width: 100%;
        padding-bottom: 5vmin;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 10vmin;

    }

    .skills_div {
        width: 100%;
        border-radius: 2vmin;



    }

    .skill_title {
        height: 10vmin;
        border-radius: 2vmin 2vmin 0 0;
        font-size: 5vmin;
    }

    .skill_div {
        border-radius: 0 0 2vmin 2vmin;
        row-gap: 4vmin;
        padding: 2vmin 3vmin;
        column-gap: 5vmin;
    }

    .skill_back_circ {
        width: 15vmin;
        height: 18vmin;
        border-radius: 100vmax;

    }

    .skill_logo {
        width: 9vmin;
    }

    .skill_name {
        padding-top: 2vmin;
        font-size: 3vmin;
        line-height: 3vmin;
    }

    .skill_level {
        padding-top: 1vmin;
        font-size: 2vmin;
        line-height: 2vmin;
    }

}