#back-back{
    position: absolute;
    top: 0;
    width: 100vw;
    background-color: yellow;
   
}


.c1{
    z-index: -1;
    position: absolute;
    top: 10vmax;
    left: -10vmax;
    width: 30vmax;
    height: 30vmax;
    flex-shrink: 0;
    border-radius: 500px;
    background: linear-gradient(180deg, #EC9A3B 0%, rgba(136, 77, 22, 0.28) 100%);
}

.c2{
    z-index: -1;
    position: absolute;
    top: 10vmax;
    right: 43vmax;
    width: 15vmax;
    height: 15vmax;
    flex-shrink: 0;
    border-radius: 300px;
    background: linear-gradient(180deg, #B5A93C 0%, rgba(136, 77, 22, 0.28) 100%);
}

.c3{
    z-index: -1;
    position: absolute;
    top: -10vmax;
    right: 0vmax;
    width: 30vmax;
    height: 30vmax;
    flex-shrink: 0;
    border-radius: 300px;
    background: linear-gradient(180deg, #ECDA3B 0%, rgba(136, 77, 22, 0.28) 100%);
}
.c4{
    z-index: -1;
    position: absolute;
    top: 40vmax;
    right: 40vmax;
    width: 35vmax;
    height: 35vmax;
    flex-shrink: 0;
    border-radius: 430px;
    background: linear-gradient(180deg, #ECDA3B 0%, rgba(136, 77, 22, 0.75) 100%);
}

.c5{
    z-index: -1;
    position: absolute;
    top: 70vmax;
    left: -5vmax;
    width: 25vmax;
    height: 25vmax;
    flex-shrink: 0;
    border-radius: 500px;
background: linear-gradient(180deg, rgba(231, 173, 104, 0.87) 0%, rgba(136, 77, 22, 0.28) 100%);
}

.c6{
    z-index: -1;
    position: absolute;
    top: 55vmax;
    right: 5vmax;
    width: 18vmax;
    height: 18vmax;
    flex-shrink: 0;
    border-radius: 391px;
    background: linear-gradient(180deg, rgba(255, 199, 0, 1) 0%, rgba(136, 77, 22, 0.14) 100%);
}

.c7{
    z-index: -1;
    position: absolute;
    top: 90vmax;
    right: 0vmax;
    width: 30vmax;
    height: 30vmax;
    flex-shrink: 0;
    border-radius: 391px;
    background: linear-gradient(180deg, rgb(234, 116, 26,.4) 0%, rgba(136, 77, 22, 0.14) 100%);
}

.c8{
    z-index: -1;
    position: absolute;
    top: 140vmax;
    right: 10vmax;
    width: 25vmax;
    height: 25vmax;
    flex-shrink: 0;
    border-radius: 391px;
    background: linear-gradient(180deg, rgb(213, 198, 59,.7) 0%, rgba(218, 115, 19, 0.744) 100%);
}


.c9{
    z-index: -1;
    position: absolute;
    top: 150vmax;
    right: 70vmax;
    width: 25vmax;
    height: 25vmax;
    flex-shrink: 0;
    border-radius: 391px;
    background: linear-gradient(180deg, rgb(255, 234, 50) 0%, rgba(136, 77, 22, 0.14) 100%);
}
.c10{
    z-index: -1;
    position: absolute;
    top: 100vmax;
    left:0vmax;
    width: 35vmax;
    height: 35vmax;
    flex-shrink: 0;
    border-radius: 391px;
    background: linear-gradient(180deg, rgb(255, 234, 50,.8) 0%, rgba(136, 77, 22, 0.14) 100%);
}
.c11{
    z-index: -1;
    position: absolute;
    top: 200vmax;
    right: 60vmax;
    width: 30vmax;
    height: 30vmax;
    flex-shrink: 0;
    border-radius: 391px;
    background: linear-gradient(180deg, rgb(219, 106, 19,.6) 0%, rgba(136, 77, 22, 0.14) 100%);
}

.c12{
    z-index: -1;
    position: absolute;
    top: 240vmax;
    right: 30vmax;
    width: 20vmax;
    height: 20vmax;
    flex-shrink: 0;
    border-radius: 391px;
    background: linear-gradient(180deg, rgba(219, 216, 19, 0.6) 0%, rgba(136, 58, 22, 0.822) 100%);
}
.c13{
    z-index: -1;
    position: absolute;
    top: 190vmax;
    right: 0vmax;
    width: 40vmax;
    height: 40vmax;
    flex-shrink: 0;
    border-radius: 391px;
    background: linear-gradient(180deg, rgba(219, 219, 19, 0.6) 0%, rgba(136, 77, 22, 0.14) 100%);
}

/* .c14{
    z-index: -1;
    position: absolute;
    top: 290vmax;
    right: 5vmax;
    width: 30vmax;
    height: 30vmax;
    flex-shrink: 0;
    border-radius: 391px;
    background: linear-gradient(180deg, rgba(219, 219, 19, 0.6) 0%, rgba(136, 77, 22, 0.14) 100%);
}

.c15{
    z-index: -1;
    position: absolute;
    top: 290vmax;
    left: 0vmax;
    width: 35vmax;
    height: 35vmax;
    flex-shrink: 0;
    border-radius: 391px;
    background: linear-gradient(180deg, rgba(219, 139, 19, 0.6) 0%, rgba(136, 77, 22, 0.14) 100%);
} */