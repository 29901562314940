@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@400;500&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #353335;
  --color-bg-faint: rgb(53, 51, 53, 0.90);
  --color-white: rgb(255, 255, 255);
  --color-white-faint: #DADADA;
  --color-white-faint-soft: rgb(218, 218, 218, .20);
  --color-primary: #DEBF61;
  --color-primary-faint: #AD9A5F;
  --color-gray: #A3A3A3;
  --color-secondary: rgb(231, 111, 0, 0.75);

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  background-color: #353335;
  font-family: 'poppins', sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
}

.titles {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sec_title {
  font-weight: 500;
}

.sec_title_one {
  font-size: 2vmax;
  line-height: 2vmax;
  color: var(--color-primary);
}

.sec_title_two {
  display: flex;
  font-size: 5vmax;
  line-height: 5vmax;
  color: var(--color-white);
}

.sec_title_two span {
  width: 8vmax;
  height: 0.3vmax;
  background-color: var(--color-white);
  margin: calc(4.4vmax - 0.4vmax) 0 0vmax 1vmax;
}


@media screen and (max-width:600px) {
  .titles {
    margin-top: 10vmin;
  }

  .sec_title_one {
    font-size: 7vmin;
    line-height: 7vmin;
  }

  .sec_title_two {
    font-size: 10vmin;
    line-height: 10vmin;
  }

  .sec_title_two span {
    display: none;
  }

}