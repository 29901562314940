#proj_sec {

    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 1vmax;
    gap: 3vmax;
    align-items: center;
    justify-content: baseline;
    padding-top: 5vmax;
}

#proj_type_sec {
    width: 40%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

#proj_type_sec ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5vmax;
}

#proj_type_sec ul .proj_type {
    padding: .4vmax 1vmax;
    width: fit-content;
    height: 60%;
    background-color: var(--color-white-faint-soft);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .8vmax;
    font-size: 1.1vmax;
    font-weight: 400;
}

#proj_type_sec ul .active {
    padding: .4vmax 1vmax;
    width: fit-content;
    height: 60%;
    background-color: var(--color-secondary);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .8vmax;
    box-shadow: 0 0 10px -2px black;
    font-size: 1.1vmax;
    font-weight: 400;
}

#proj_type_sec ul .proj_type:hover {
    /* background-color: var(--color-secondary); */
    color: var(--color-primary);
    border: none;
    cursor: pointer;
}

#projects_div {
    width: 98%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(auto-fill, 30vmax);
    justify-content: space-between;
    grid-row-gap: 3vmax;
    margin-bottom: 10vmax;
}

.proj_div {
    width: 30vmax;
    height: fit-content;
    background-color: var(--color-white-faint-soft);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vmax;
    gap: .5vmax;
    border-radius: .5vmax;
    /* transition: all .2s linear; */
}


.proj_div:hover {
    /* transform: scale(1.05); */
    z-index: 10;
    box-shadow: 0 0 30px -1px rgb(0, 0, 0);
}

.proj_img_div {
    width: 100%;
    height: 20vmax;
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .5vmax;
    overflow: hidden;

}

.proj_img_div img {
    width: 100%;
    border-radius: .5vmax;
}

.proj_desc_div {
    width: 100%;
    height: fit-content;
    padding: .5vmax 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.proj_desc {
    width: 100%;
    padding: 0 0 .5vmax 0;
    font-size: 1.1vmax;
    font-weight: 500;
}

.view_more {
    font-size: 1vmax;
    font-size: 400;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: baseline;
    gap: .2vmax;
    cursor: pointer;
}

.vm_icn {
    width: 1vmax;
}



@media screen and (max-width: 600px) {
    #proj_sec {
        padding: 0 2vmin;
        gap: 6vmin;
        padding-top: 6vmin;
    }

    #proj_type_sec {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: aliceblue; */
    }

    #proj_type_sec ul {
        gap: 5vmin;
        display: flex;
        flex-wrap: wrap;
        padding: 1vmin 0;


    }

    #proj_type_sec ul .proj_type {
        padding: 1vmin 5vmin;
        border-radius: 3vmin;
        font-size: 4vmin;
        height: 70%;
    }

    #proj_type_sec ul .active {
        padding: 1vmin 5vmin;
        border-radius: 3vmin;
        font-size: 4vmin;
        background-color: var(--color-secondary);
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;

    }

    #proj_type_sec ul .proj_type:hover {
        padding: 1vmin 5vmin;
        border-radius: 3vmin;
        font-size: 4vmin;
    }


    #projects_div {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: baseline;
        align-items: center;
        gap: 7vmin;
        margin-bottom: 15vmin;
    }

    .proj_div {
        width: 90%;
        padding: 3vmin;
        gap: 2vmin;
        border-radius: 2vmin;
    }

    .proj_img_div {
        height: 70vmin;
        background-color: var(--color-white);
        border-radius: 2vmin;

    }

    .proj_img_div img {
        width: 100%;
        border-radius: 2vmin;
    }

    .proj_desc_div {
        padding: 1vmin 0 0 0;
    }

    .proj_desc {
        padding: 0 0 1vmin 0;
        font-size: 3.5vmin;
    }

    .view_more {
        font-size: 3.5vmin;
        gap: 1vmin;

    }

    .vm_icn {
        width: 4vmin;
    }


}