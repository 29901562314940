#testi_sec {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 1vmax;
    gap: 5vmax;
    align-items: center;
    margin-bottom: 17vmax;
}


#testmns_sec {
    width: 98%;
    height: fit-content;
    align-items: baseline;
    overflow: hidden;
    border-radius: .5vmax;
}




.testimonial {
    width: 25vmax;
    height: 100%;
    background-color: var(--color-white-faint-soft);
    display: flex;
    flex-direction: column;
    gap: 2vmax;
    padding: 1vmax;
    border-radius: .9vmax;
    pointer-events: none;
    margin: 0 1vmax;
    transition: all 0.5s;

}


.person_div {
    width: 100%;
    display: flex;
    height: fit-content;
    gap: 1vmax;
    justify-content: baseline;
    align-items: center;
}

.person_img_div {
    width: 4vmax;
    height: 4vmax;
    background-color: var(--color-white);
    border-radius: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* background-color: aquamarine; */

}

.person_img {
    width: 2.8vmax;
}

.person_detail {
    width: 70%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: .5vmax;

}


.person_detail .name {
    width: 100%;
    display: flex;
    justify-content: baseline;
    align-items: center;
    font-weight: 500;
    font-size: 1.1vmax;
    line-height: 1.1vmax;

}

.person_detail .prof {
    width: 100%;
    display: flex;
    justify-content: baseline;
    align-items: center;
    font-weight: 300;
    font-size: .8vmax;
    line-height: .8vmax;
    color: var(--color-white-faint);
}

.test_cont {
    width: 100%;
    height: fit-content;
    font-weight: 350;
    font-size: .95vmax;
    color: var(--color-white-faint);

}


@media screen and (max-width:600px) {

    #testi_sec {
        padding: 0 1vmin;
        gap: 10vmin;
    }


    #testmns_sec {
        width: 100%;
        gap: 1vmin;
    }



    .btn {
        width: 6vmin;
    }

    #testimonials {

        gap: 4vmin;
        cursor: grab;

    }

    .testimonial {
        width: 86vmin;
        height: 100%;
        display: flex;
        gap: 3vmin;
        padding: 2vmin;
        border-radius: 2vmin;
        pointer-events: none;
    }

    .person_div {
        gap: 2vmin;
    }

    .person_img_div {
        width: 14vmin;
        height: 14vmin;
    }

    .person_img {
        width: 6vmin;
    }

    .person_detail {
        gap: 1vmin;

    }


    .person_detail .name {
        font-size: 4vmin;
        line-height: 4vmin;

    }

    .person_detail .prof {
        font-size: 3vmin;
        line-height: 3vmin;
    }

    .test_cont {
        font-size: 3.5vmin;
    }

}